import { FC, useState } from 'react'

import { CheckCircleIcon } from '@heroicons/react/24/solid'
import {
  FindUserLearnerCategoryListQuery,
  FindUserLearnerCategoryListQueryVariables,
} from 'types/graphql'
import { useMediaQuery } from 'usehooks-ts'

import { CellSuccessProps } from '@redwoodjs/web'

import CircularProgressWithLabel from 'src/components/CircularProgressWithLabel/CircularProgressWithLabel'
import { default as Placeholder } from 'src/components/Empty/Empty'
import FilterNavTabs from 'src/components/FilterNavTabs/FilterNavTabs'
import { DirectoryMemberMembershipGroup } from 'src/components/InformerDirectoryUserCell'
import SearchGroups from 'src/components/Learner/Library/SearchGroups/SearchGroups'
import { default as LoadingSpinner } from 'src/components/Library/Loading'
import useAnalytics from 'src/lib/hooks/useAnalytics'
import useReadLocalStorage from 'src/lib/hooks/UseReadLocalStorage'
import { useAuth } from 'src/Providers'
import { filterCourses } from 'src/Util'

import LearnerSummary from './LearnerSummary/LearnerSummary'
// Nav Tab Items
let navTabItems = [
  { title: 'In Progress', count: 0 },
  { title: 'Completed', count: 0 },
]

let inProgressCategories: CourseSummaryLearnerCategory[] = [],
  completedCategories: CourseSummaryLearnerCategory[] = []

let inProgressCourseNumber = 0,
  completedCourseNumber = 0

const continueLearningCat = {
  id: -1,
  name: 'Continue Learning...',
  learnerCourses: [],
}

const processLearnerCategories = (
  learnerCategories: CourseSummaryLearnerCategory[],
) => {
  //reset to clean state
  inProgressCategories = []
  completedCategories = []

  continueLearningCat.learnerCourses = []

  inProgressCourseNumber = 0
  completedCourseNumber = 0

  for (const category of learnerCategories) {
    const inProgressCourses = [],
      completedCourses = []

    for (const learnerCourse of category.learnerCourses) {
      const { reporting } = learnerCourse

      //check status of course based upon user course tracking
      if (reporting?.attempts.length > 0) {
        if (reporting?.attempts?.[0]?.status === 'COMPLETED') {
          completedCourses.push(learnerCourse)
        } else if (reporting?.attempts?.[0]?.status === 'IN_PROGRESS') {
          inProgressCourses.push(learnerCourse)
          continueLearningCat.learnerCourses.push(learnerCourse) //add to continue learning array
        }
      }
    }

    inProgressCourseNumber += inProgressCourses.length
    completedCourseNumber += completedCourses.length

    //push courses into respective arrays
    if (inProgressCourses.length)
      inProgressCategories.push({
        ...category,
        learnerCourses: inProgressCourses,
      })

    if (completedCourses.length)
      completedCategories.push({
        ...category,
        learnerCourses: completedCourses,
      })
  }

  // if there are any continue learning courses, add it to the respect categories for users to see which they still need to completed
  if (continueLearningCat.learnerCourses.length) {
    learnerCategories.unshift(
      continueLearningCat as CourseSummaryLearnerCategory,
    )
  }
}

const setNavItemCounts = () => {
  //update course numbers for each type
  navTabItems = navTabItems.map((obj) => {
    switch (obj.title) {
      case 'In Progress':
        return { ...obj, count: inProgressCourseNumber }
      case 'Completed':
        return { ...obj, count: completedCourseNumber }
      default:
        return obj
    }
  })
}

const getSelectedTab = () => {
  // start by assuming the tab selected has data
  let selectedTab = 'In Progress'

  // if that tab has no data, try and select another tab, otherwise default to first
  if (completedCourseNumber > 0 && inProgressCourseNumber === 0)
    selectedTab = 'Completed'

  return selectedTab
}

const getSelectedContent = (tabSelected) => {
  //put all the categories into an array
  const categories = [inProgressCategories, completedCategories]
  //grab the contents if the selected tab has contents, otherwise dont
  return categories?.[tabSelected]?.length > 0 ? categories[tabSelected] : []
}

export const QUERY = gql`
  query FindUserLearnerCategoryListQuery(
    $membershipIds: [Int!]
    $reportingFlags: ReportingFlags!
  ) {
    learnerCategories: learnerCategoriesByClient(status: PUBLISHED) {
      id
      name
      aclPrincipals {
        principalId
        principalType
        principal {
          __typename
          ... on ACLPrincipalMembershipGroup {
            membershipGroup {
              id
              name
            }
          }
        }
      }
      learnerCourses(status: PUBLISHED) {
        id
        name
        heroUrl
        learnerCategoryId
        learnerActivities(status: PUBLISHED) {
          id
          name
          learnerTasks(status: PUBLISHED) {
            id
            name
          }
        }
        reporting(
          reportingFlags: $reportingFlags
          membershipIds: $membershipIds
        ) {
          learnerCourseId
          attempts {
            status
            percentage
            relatedTracking {
              ... on LearnerCourseTracking {
                id
                membershipId
                createdAt
                updatedAt
                status
              }
            }
            tasks {
              learnerTaskId
              type
              name
              attempt {
                status
                percentage
                relatedTracking {
                  ... on LearnerTaskTracking {
                    id
                    membershipId
                  }
                }
              }
            }
          }
        }
      }
    }
    membershipGroups: membershipGroupsByClient {
      id
      name
    }
  }
`

export const Loading = () => (
  <div className="pt-8">
    <LoadingSpinner />
  </div>
)

export const Failure = ({ error }) => (
  <div style={{ color: 'red' }}>Error: {error.message}</div>
)

export const afterQuery = ({ learnerCategories, ...rest }) => {
  return { learnerCategories, ...rest }
}
type Props = CellSuccessProps<
  FindUserLearnerCategoryListQuery,
  FindUserLearnerCategoryListQueryVariables
> & {
  clientId: number
  userId: number
  membershipIds: number[]
  availableMembershipGroups: DirectoryMemberMembershipGroup[]
  reportingFlags: {
    includeCourseStats: boolean
    includeCourseAttempts: boolean
    includeTaskStats: boolean
    includeTaskType: boolean
    lastAttempt: boolean
    includeArchived: boolean
  }
}
export type CourseSummaryLearnerCategory = Props['learnerCategories'][0]
export type CourseSummaryLearnerCourse =
  CourseSummaryLearnerCategory['learnerCourses'][0]
export type AclPrincipal = CourseSummaryLearnerCategory['aclPrincipals'][0]

export const Success: FC<Props> = ({
  learnerCategories,
  // membershipGroupIds,
  clientId,
  userId,
  availableMembershipGroups,
  membershipGroups: allMembershipGroups,
  queryResult: { refetch },
}) => {
  // Local Storage Name
  const localStorageName = `clientId-${clientId}-$userId-${userId}-userLearnerProgress`

  // Pull in the local storage obj
  const groupsLocalStorageData =
    useReadLocalStorage<{ label: string; value: number }[]>(localStorageName) ||
    []

  const membershipGroupIds = groupsLocalStorageData.map((group) => group.value)

  const { currentUser } = useAuth()

  const [searchVal, setSearchVal] = useState<string>('')
  const [showSummary, setShowSummary] = useState<boolean>(false)
  const [selectedCourse, setSelectedCourse] =
    useState<CourseSummaryLearnerCourse | null>(null)
  const [selectedGroups, setSelectedGroups] = useState<number[]>(
    membershipGroupIds || [],
  )
  const [selectedAttempt, setSelectedAttempt] = useState<number>(0)
  const isMobile = useMediaQuery('(max-width: 769px)')
  const isSmallDesktop = useMediaQuery('(max-width: 1771px)')

  if (selectedGroups.length > 0) {
    learnerCategories = learnerCategories.filter((learnerCategory) => {
      const aclEntries = learnerCategory.aclPrincipals.filter(
        ({ principalType }) => principalType === 'MEMBERSHIPGROUP',
      )
      // compare the acl entries to the selected groups, acl entries must include all selected groups
      return selectedGroups.every((selectedGroup) =>
        aclEntries.some(
          ({ principal }) =>
            principal.__typename === 'ACLPrincipalMembershipGroup' &&
            principal.membershipGroup.id === selectedGroup,
        ),
      )
    })
  }

  //filter any courses by search value
  learnerCategories = filterCourses<CourseSummaryLearnerCategory>(
    learnerCategories,
    searchVal,
  )

  //process the learner categories data to find out what is contained within
  //get counts for each type of course
  //files the respective courses into each type
  processLearnerCategories(learnerCategories)

  //set the counts for each type
  setNavItemCounts()

  //figure out what tab is supposed to be initially selected
  const selectedTab = getSelectedTab()

  //set the selected intial tab to the selected one
  const [tabSelected, setTabSelected] = useState(
    navTabItems.findIndex((tabItem) => selectedTab === tabItem.title),
  )

  //if not in support mode
  //set page category contents
  const filteredCategories = getSelectedContent(tabSelected)

  //update current tab if tab is clicked
  const setTabSelectedCallBack = (tabTitle) => {
    setTabSelected(
      navTabItems.findIndex((tabItem) => tabTitle === tabItem.title),
    )
  }

  const { trackEvent } = useAnalytics()

  return (
    <>
      <div className={`border-b bg-white ${!isSmallDesktop && 'pr-10'}`}>
        <div className="flex flex-col justify-between">
          {isMobile && (
            <SearchGroups
              searchVal={searchVal}
              setSearchVal={setSearchVal}
              localStorageName={localStorageName}
              setSelectedGroups={setSelectedGroups}
              specifiedMembershipGroups={availableMembershipGroups}
              GAEventCategory="People"
            />
          )}
          <div
            className={`flex w-full ${isSmallDesktop ? 'flex-col' : 'flex-row'} justify-center py-2`}
          >
            <div className={`mx-auto pl-10 ${isSmallDesktop ? 'mb-2' : ''}`}>
              <FilterNavTabs
                variant={'standard'}
                navTabs={navTabItems}
                onTabClickCallBack={setTabSelectedCallBack}
                selectedTab={tabSelected}
                GATracker={{
                  category: 'Learner',
                  eventName: 'change tab learner profile',
                }}
              />
            </div>
            {!isMobile && (
              <div
                className={`flex flex-1 ${isSmallDesktop ? 'justify-center' : 'justify-end'}`}
              >
                <SearchGroups
                  searchVal={searchVal}
                  setSearchVal={setSearchVal}
                  localStorageName={localStorageName}
                  setSelectedGroups={setSelectedGroups}
                  specifiedMembershipGroups={availableMembershipGroups}
                  GAEventCategory="People"
                />
              </div>
            )}
          </div>
        </div>
      </div>
      <div className={`gap-4 ${isMobile && 'mb-12'}`}>
        {filteredCategories.map(
          (category) =>
            category.learnerCourses.length > 0 && (
              <div
                key={'category-' + category.id}
                className="flex flex-col rounded bg-gray-100 px-10 pb-3 pt-10"
              >
                <div className="mb-4">
                  <p className="text-xl font-medium text-gray-900">
                    {category.name}
                  </p>
                </div>
                <div
                  className={`flex ${
                    isMobile ? 'flex-row' : 'flex-wrap'
                  } overflow-x-auto`}
                >
                  {category.learnerCourses?.map((course) => {
                    const percentage =
                      course.reporting?.attempts?.[0]?.percentage || 0

                    return (
                      <div
                        key={'course-' + course.id}
                        className={
                          'mb-4 mr-4 min-h-48 w-60 rounded-lg border border-gray-100 bg-white shadow transition-transform duration-300 hover:-translate-y-1 hover:translate-x-1 hover:drop-shadow-xl'
                        }
                        onKeyUp={() => {}}
                        onClick={() => {
                          if (!selectedCourse) {
                            setShowSummary(!showSummary)
                            setSelectedCourse(course)
                            setSelectedAttempt(
                              course.reporting?.attempts?.length - 1,
                            )
                            trackEvent('People', 'open learner summary', {
                              selectedCourse: course?.name,
                            })
                          }
                        }}
                        role={'button'}
                        tabIndex={0}
                      >
                        <div className="min-h-32 w-full">
                          {course.heroUrl ? (
                            <img
                              className="h-32 w-full object-cover hover:z-50"
                              src={course.heroUrl}
                              alt={course.name}
                            />
                          ) : (
                            <Placeholder
                              className="min-h-32 rounded-md bg-gray-200"
                              image
                            />
                          )}
                          <div className="space-between mx-3 my-2 flex min-h-10 flex-1">
                            <div
                              className={
                                'my-2 mr-3 min-h-5 w-44 overflow-hidden text-ellipsis text-sm'
                              }
                            >
                              {course.name.trim()}
                            </div>
                            <div className="flex h-full items-center justify-center">
                              {percentage < 100 ? ( //if there are any potential in progress tasks
                                <CircularProgressWithLabel
                                  text={
                                    percentage === 0 &&
                                    !currentUser.isClientAlias
                                      ? 'start'
                                      : null
                                  }
                                  value={percentage}
                                />
                              ) : (
                                // if all the tasks have been completed
                                <CheckCircleIcon
                                  className={'h-10 w-10 text-green-500'}
                                />
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                  })}
                </div>
              </div>
            ),
        )}
        {selectedCourse && (
          <LearnerSummary
            course={selectedCourse}
            courseMembershipGroupIds={learnerCategories
              .find(
                (learnerCategory) =>
                  learnerCategory.id === selectedCourse.learnerCategoryId,
              )
              .aclPrincipals.reduce((acc, aclPrincipal) => {
                return aclPrincipal.principalType === 'MEMBERSHIPGROUP'
                  ? [...acc, aclPrincipal.principalId]
                  : acc
              }, [])}
            allMembershipGroups={allMembershipGroups}
            showSummary={showSummary}
            setShowSummary={setShowSummary}
            selectedAttempt={selectedAttempt}
            setSelectedAttempt={setSelectedAttempt}
            refetchLearnerCategories={refetch}
          />
        )}
      </div>
    </>
  )
}
